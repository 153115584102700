export function printStyle() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    if (style.sheet) {
        console.log(style.sheet.cssRules); // length is 0, and no rules
    }
    return style;
}

export function addMediaPrintCss(margin: string = '0', taggedA4Layout: boolean = false, memberFlyer: boolean = false) {
    document.body.classList.remove('pinsheet-print', 'flyer-print')

    let size = taggedA4Layout ? 'A4' : 'letter'
    let width = taggedA4Layout ? '8.5in' : '8.5in'
    let height = taggedA4Layout ? '11.8in' : '11in'
    
    let id = 'printStyle'

    const existingPrintStyle = document.getElementById(id);
    console.log(existingPrintStyle, '***existing-styles')
    if(existingPrintStyle)
        existingPrintStyle.remove();

    let printStyleCss = ''
    let style = document.createElement("style");
    style.id = id

    if(memberFlyer) {
        document.body.classList.add('flyer-print')
        width = '21cm'
        height = '29.7cm'
        printStyleCss = `
        @media print {
            @page {
                width: ${width} !important;
                height: ${height} !important;
                // size: auto !important;
                margin: ${margin} !important;
            }
        }
        `
    }
    else {
        document.body.classList.add('pinsheet-print')
        printStyleCss = `
        @media print {
            @page {
                size: ${size} !important;
                // size: auto !important;
                margin: ${margin} !important;
            }
        }
        `
    }
    style.textContent = printStyleCss;
    document.head.appendChild(style);
}