import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { logoutAction } from 'src/app/store/actions/logout.action';
import { impersonateAction, userDetails } from 'src/app/store/actions/user.actions';
import { HttpService } from 'src/app/core/services/http/http.service';
import { selectUserCollection, selectUserImpersonate } from 'src/app/store/selectors/user.selectors';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../login/login.service';
import { selectClientsCollection, selectClientsProfileCollection } from 'src/app/store/selectors/clients.selectors';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { addMediaPrintCss } from 'src/app/common';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild("impersonateDialogRef") impersonateDialogRef: TemplateRef<any>;
  @ViewChild("hamburgerMenu") hamburgerMenu: ElementRef<any>;
  @ViewChild("profileMenu") profileMenuElement: ElementRef<any>;
  @ViewChild("porfileIcon") profileIconElement: ElementRef<any>;
  @ViewChild("notificationDialogRef") notificationDialogRef:TemplateRef<any>;
  showNotification: boolean;
  superAdmin: boolean;
  superAdminView: boolean;
  user$ = this.store.select(selectUserCollection);
  impersonate: boolean = false;
  clientLabel: string = 'SELECT CLIENT';
  selectedClientId: string;
  selectedUserId: string;
  userList: any = [];
  userLabel: string = 'SELECT USER';
  userRight: string = ''
  impersonate$ = this.store.select(selectUserImpersonate);
  clientsData$ = this.store.select(selectClientsCollection);
  clientsProfile$ = this.store.select(selectClientsProfileCollection)
  clientsData: any;
  clientsDataSubscription: any;
  isLogoutClicked: boolean = false;
  profileMenuOpen: boolean = false;
  enablePrint: boolean = false;
  alertlist: any;
  alertSubject: any = [];
  alertDateFormat: any;
  clientProfileData: any;
  subscriptionType: string;
  isSubscriptionActive : boolean;
  remainingTrialDays:number;
  clientId:number|null = null;
  // clientsProfile$ = this.store.select(selectClientsProfileCollection)
  // clientProfileData: any
  @Input() memberFlyerQRCodeURL: string;
  @Input() showBanner: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() desktop: boolean = false;
  @Output() showBannerChange = new EventEmitter<boolean>();
  @Input() selectedClientName: string;
  @Output() selectedClientNameChange = new EventEmitter<string>();
  @Output() onHamburgerClick = new EventEmitter<ElementRef<any>>();
  @HostListener('window: click', ['$event.target'])
  OnClick(event: any) {
    let resourcePanel = document.querySelector('#resource') as HTMLElement
    let resourceButton = document.querySelector('#resourceButton') as HTMLElement
    if(this.isMobile) {
      resourceButton = document.querySelector('#resourceButtonMobile') as HTMLElement
    }
    if (resourcePanel != undefined) {
      if (!resourcePanel.contains(event) && (event.id != 'resourceButtonMobile' && event.id != 'resourceButton')) {
        this.hideNotification()
      }
    }
  }

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private store: Store, 
    private http: HttpService,
    private dialog: MatDialog,
    private loginService: LoginService,
    private ngxLoader: NgxUiLoaderService,
    private renderer: Renderer2
  ) { 
    this.user$.subscribe((data)=>{
      if(this.impersonate)
        this.superAdmin = true
      else 
        this.superAdmin = data.RightsId == 1

      this.superAdminView = data.RightsId == 1
    })
    this.impersonate$.subscribe((data) => {
      this.impersonate = data
    })
    this.renderer.listen('document', 'click', (e:Event) => {
      let target = <HTMLElement>e.target;
      if(this.profileMenuElement && this.profileMenuElement.nativeElement != target
          && this.profileIconElement.nativeElement != target.parentElement &&  this.profileIconElement.nativeElement != target) {
            if(this.isMobile && this.profileMenuOpen) {
              this.toggleProfileMenu()
            }
      }
    })
    // this.clientsProfile$.subscribe((data) => {
    //   this.clientProfileData = data
    //   this.memberFlyerQRCodeURL = `${location.origin}/member?code=${this.clientProfileData.MemberPassword}`
    // })
    this.clientsProfile$.subscribe((data) => {
      this.clientProfileData = data
      this.clientId = this.clientProfileData.ClientId
      this.subscriptionType = this.clientProfileData.SubscriptionType
      this.isSubscriptionActive = this.clientProfileData.IsSubscriptionActive
      var trialEndDate = new Date(this.clientProfileData.TrialEndDate)
      const currentDate = new Date();
      const timeDifference = trialEndDate.getTime() - currentDate.getTime();
      var daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
      this.remainingTrialDays = daysRemaining
    })
  }

  logOut() {
    this.onImpersonateExit(false)
    let refreshToken = this.cookieService.get('refreshToken')
    this.isLogoutClicked = true
    
    if(this.cookieService.get('externalSession') == 'true') {
      this.http.post(`/api/users/logout?refreshToken=${refreshToken}`, {})
        .subscribe((data) => {
          console.log(data, '***data')
          if (data.Status == 'Success') {
            this.store.dispatch(logoutAction())
            this.cookieService.delete('accessToken')
            this.cookieService.delete('clientId')
            this.cookieService.delete('courseId')
            this.cookieService.delete('selectedPinsheetDate')
            this.cookieService.delete('pinsheetDate')
            this.cookieService.delete('refreshToken')
            this.cookieService.delete('userId')
            this.cookieService.delete('loggedInUserId')
            this.cookieService.delete('onImpersonate')
            this.cookieService.delete('impersonateRefreshToken')
            this.cookieService.delete('impersonateAccessToken')
            this.cookieService.delete('clientBeforeImpersonate')
            this.cookieService.delete('courseBeforeImpersonate')
            this.cookieService.delete('externalSession')
            this.cookieService.delete('lastActive', undefined, environment.ACTIVE_TIME_DOMAIN)
            // this.cookieService.deleteAll('/login','localhost')
            this.router.navigate(['login'])
          }
        }
      )
    }
    else {
      let idToken = this.cookieService.get('idToken')
      window.location.href = `${environment.BASE_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}/logout`
    }

  }

  hideNotification() {
    this.showNotification = false
  }

  showResource(): void {
    this.showNotification = true
  }
  ngOnInit(): void {
    this.checkUnread('/api/message/check/unread')
    let clientId = this.cookieService.get('clientId')
    if(clientId) {
      this.onSelectClientId(clientId)
    }
    this.isLogoutClicked = false
    if(this.impersonate && !this.selectedClientName) {
      this.getClients('/api/users/clients')
    }
  }
  checkUnread(path: string) {
    this.http.get(path)
      .subscribe((data) => {
        if (data.Status == 'Success') {
          if (data.Unread == false) {
            let element = document.getElementById('alert-models')
            console.log(element)
            if (element) {
              element.style.display = 'none'
            }
          }
          else {
            this.alertMessage(`/api/message/list`)
            let element = document.getElementById('alert-models')
            if (element) {
              element.style.display = 'block'
            }
          }
        }
      }
      )
  }

  alertMessage(path: string) {
    this.http.get(path)
      .subscribe((data) => {
        if (data.Status == 'Success') {
          this.alertlist = data.Data
          this.alertSubject = this.alertlist[0]
          const dateFormat = moment.utc(this.alertSubject.AlertDate).local().format('MMMM Do YYYY, h:mm a')
          this.alertDateFormat = dateFormat
          this.dialog.open(this.notificationDialogRef, { disableClose: true,})
        }
      })
  }

  onNotificationDialogueClose() {
    this.dialog.closeAll()
    let loginCount= Number(this.cookieService.get('loginCount'))
    if(loginCount>=3){
      this.http.post(`/api/message/mark/read?alertId=${this.alertSubject.AlertId}`, {})
      .subscribe((data) => {
        if (data.Status == 'Success') {
          this.alertlist.map((v: any) => {
            if (v.AlertId == this.alertSubject.AlertId && v.IsRead == false) {
              v.IsRead = true;
            }
            return v;
          })
        }
      })
      let element = document.getElementById('alert-models')
      if (element) {
        element.style.display = 'none'
      }
    }
    
  }

  openImpersonateDialog() {
    this.clientsDataSubscription = this.clientsData$.subscribe((data) => {
      if(!data.length && !this.isLogoutClicked) {
        this.getClients('/api/users/clients')
      }
      else {
        this.clientsData = data
        this.clientsData.filter((v: any) => {
          if (Number(v.id) == Number(this.cookieService.get('clientId'))) {
            this.selectedClientName = v.ClientName
            this.clientLabel = this.selectedClientName
            this.onSelectClientId(v.id)
          }
        })
      }
      console.log(this.clientsData, '***clients-data-imp-open')
    })
    this.dialog.open(this.impersonateDialogRef, { disableClose: true, width: '16rem', panelClass: 'impersonate-modal' })
  }

  getClients(path: string, toDispatch: boolean = false) {
    this.http.get(path)
      .subscribe((data) => {
        if (data.Status == 'Success') {
          const clientsData = data.Data
          clientsData.map((v: any) => {
            v.name = v.ClientName
            v.id = v.ClientId
          })
          clientsData.filter((v: any) => {
            if (Number(v.id) == Number(this.cookieService.get('clientId'))) {
              this.selectedClientName = v.ClientName
              this.selectedClientNameChange.emit(this.selectedClientName)
              this.clientLabel = this.selectedClientName
              this.onSelectClientId(v.id)
            }
          })
          this.clientsData = clientsData
          console.log(this.clientsData, '***clients-data-imp-open2')
        }
      }
    )
  }



  onImpersonateDialogClose() {
    this.dialog.closeAll()
  }

  onImpersonate() {
    this.ngxLoader.start()
    this.http.post(`/api/users/impersonate?impersonateUserId=${this.selectedUserId}`, {})
    .subscribe((data) => {
      if(data.Status = 'Success') {
        this.impersonate = true
        const impersonate = this.impersonate
        this.cookieService.set('clientBeforeImpersonate', this.cookieService.get('clientId'))
        this.cookieService.set('courseBeforeImpersonate', this.cookieService.get('courseId'))
        this.cookieService.set('clientId', this.selectedClientId)
        this.cookieService.set('onImpersonate', this.impersonate ? '1' : '0')
        this.cookieService.set('impersonateAccessToken', data.Data.AccessToken)
        this.cookieService.set('impersonateRefreshToken', data.Data.RefreshToken)
        this.dialog.closeAll()
        this.store.dispatch(impersonateAction({ impersonate }))
        window.location.pathname = '/pinsheet'
      }
      else {
        this.impersonate = false
        const impersonate = this.impersonate
        this.store.dispatch(impersonateAction({ impersonate }))
      }
    })
  }

  onImpersonateExit(isNavigate: boolean=true) {
    this.ngxLoader.start()
    this.impersonate = false
    const impersonate = this.impersonate
    this.store.dispatch(impersonateAction({ impersonate }))
    let impersonateRefreshToken = this.cookieService.get('impersonateRefreshToken')
    if(impersonateRefreshToken) {
      this.http.post(`/api/users/logout?refreshToken=${impersonateRefreshToken}`, {})
        .subscribe((data) => {
          this.cookieService.set('clientId', this.cookieService.get('clientBeforeImpersonate'))
          this.cookieService.set('courseId', this.cookieService.get('courseBeforeImpersonate'))
          this.cookieService.set('onImpersonate', this.impersonate ? '1' : '0')
          this.cookieService.delete('impersonateRefreshToken')
          this.cookieService.delete('impersonateAccessToken')
          if(isNavigate) {
            window.location.pathname = '/pinsheet'
          }
        }
      )
    }
  }

  onSelectClientId(value: string) {
    this.selectedClientId = value
    this.userList = []
    this.userLabel = 'SELECT USER'
    this.userRight = ''
    this.getUserList(`/api/users/list?clientId=${this.selectedClientId}`)
  }

  setSelectedClientName(value: string) {
    this.clientLabel = value
  }

  getUserList(path:string) {
    this.http.get(path)
      .subscribe((data) => {
        if(data.Status == 'Success') {
          let userListData = data.Data
          userListData = userListData.filter((v:any) => v.Active == true)
          userListData.map((v: any) => {
            v.id = v.UserId,
            v.name = `${v.FirstName} ${v.LastName}`
          })
          this.userList = userListData
          console.log(this.userList, '***user-list-impersonate')
        }
      })
  }

  setUserSelectedValue(value: string) {
    this.userLabel = value
  }

  onUserSelection(value: string) {
    this.selectedUserId = value
    let selectedUser = this.userList.find((v: any) => v.id == value)
    if(selectedUser.RightsId == 2) {
      this.userRight = selectedUser.UserRight
    }
  }

  openMenu() {
    this.onHamburgerClick.emit(this.hamburgerMenu)
  }

  toggleProfileMenu() {
    this.profileMenuOpen = !this.profileMenuOpen
  }

  enableMemberAccessFlyer(event: MouseEvent) {
    this.enablePrint = true
  }

  onLoadFlyer() {
    addMediaPrintCss('0', false, true)
    window.print()
    this.enablePrint = false
  }

  onFlyerLoadingError(event: any) {
    console.log(event, '***logo-loading-error')
    this.enablePrint = false
  }

  openMemberPinsheetPage(event: MouseEvent) {
    window.open(this.memberFlyerQRCodeURL)
  }

}